import { getUserNru } from "../../../services/tipddy/nru";
import { sence_config } from "../strings";

const query = (selector) => document.querySelector(selector) || null;
/**
 *
 * Validate fields and data to send the form and navigate to sence sistems
 */

export const initSenceApplication = (args) => {
  let {
    idSence,
    auth,
    session = null,
    isLogin,
    codCourse: nru,
    course_id,
  } = args;

  console.log("auth: ", auth);

  let dv = auth.user.idnumber[auth.user.idnumber.length - 1] || null;
  let rut = auth.user.idnumber.slice(0, -1) || null;
  let rut_value = null;
  if (rut && auth.user.idnumber.includes("-")) {
    rut_value = `${rut}${dv}`;
  } else if (rut) {
    rut_value = `${rut}-${dv}`;
  }

  let form = query("#form_sence");
  let CodSence = query("#CodSence");
  let idSesionAlumno = query("#IdSesionAlumno");
  let runAlumno = query("#RunAlumno");
  let codCurso = query("#CodigoCurso");

  CodSence.value = idSence;
  idSesionAlumno.value = auth.user.token;
  runAlumno.value = rut_value;
  codCurso.value = nru;

  if (!isLogin) {
    let idSesionSence = query("#IdSesionSence");
    idSesionSence.value = session.IdSesionSence;
    query("#form_sence").action = sence_config.URL_LOGOUT;
    query("#UrlRetoma").value = sence_config.URL_RETURN_LOGOUT;
    query("#UrlError").value = sence_config.URL_LOGOUT_ERROR;
  } else {
    localStorage.setItem("idSence", idSence);
    localStorage.setItem("current_course", course_id);
  }

  console.log({ idSence });
  console.log({ rut_value });
  console.log("user token:", auth.user.token);
  console.log({ nru });
  console.log({ course_id });
  form.submit();
};

/**
 *
 * Verify codSence from course
 *
 */
export const verifyCodSence = ({ currentCourse, user_id }) => {
  if (currentCourse.idnumber.includes("sence-")) {
    let id = currentCourse.idnumber.split("sence-")[1];
    return {
      isSence: true,
      idSence: id,
    };
  }
  if (currentCourse.idnumber.includes("sencetest-") && user_id === 98) {
    let id = currentCourse.idnumber.split("sencetest-")[1];
    console.log("sencetest:", id, user_id);
    return {
      isSence: true,
      idSence: id,
    };
  }
  return {
    isSence: false,
  };
};

export const verifyRegistration = async (course_id, user_id) => {
  let nru = null;
  /* Ingreso manual de nru por user id segun tabla php my admin */

  // let array_nru1 = [96, 69, 97, 70, 71];
  // if (array_nru1.includes(user_id)) {
  //   nru = "6371868";
  // }

  // let array_nru2 = [
  //   72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
  //   91, 92,
  // ];
  // if (array_nru2.includes(user_id)) {
  //   nru = "6371867";
  // }
  // let array_nru3 = [
  //   10249, 10573, 41, 20530, 11649, 42, 11650, 47, 10570, 56, 14079, 57,
  // ];
  // if (array_nru3.includes(user_id)) {
  //   nru = "6371589";
  // }
  // let array_nru4 = [93, 94, 95];
  // if (array_nru4.includes(user_id)) {
  //   nru = "6371869";
  // }
  // /* Fin ingreso manual tabla php my admin */
  // if (
  //   !array_nru1.includes(user_id) &&
  //   !array_nru2.includes(user_id) &&
  //   !array_nru3.includes(user_id) &&
  //   !array_nru4.includes(user_id)
  // ) {
  //   console.log("no pertenece al array");
  //   if (course_id === "13") {
  //     /* Maro legal y normativa de seguridad */
  //     nru = "6371589";
  //   }
  //   /* Introducción al merchandising */
  //   if (course_id === "17") {
  //     nru = "6374045";
  //   }
  //   /* Comunicación Efectiva */
  //   if (course_id === "21") {
  //     nru = "6371587";
  //   }
  //   /* Alfabetización digital */
  //   if (course_id === "25") {
  //     nru = "6371591";
  //   }
  // }
  /* Fin ingreso manejo manual nru */
  nru = await getUserNru(course_id, user_id);
  return nru;
};

export const verifySenceSession = () => {
  let isActive = localStorage.getItem("senceSession");
  return {
    isActive,
  };
};
