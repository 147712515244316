import axios from "axios";
import { useMoodle } from "./moodle";
import { createContext, useContext, useState } from "react";

const MallasContext = createContext(null);

export const MallasProvider = ({ children }) => {
  const moodle = useMoodle();
  const [malla, setMalla] = useState(null);

  /**
   * Requirements to create, display, and assign a malla
   *
   *
   * Ability to retrieve moodle courses
   *
   *
   * Ability to create categories
   *
   *
   * Ability to create levels
   *
   *
   * Ability to add category to course
   *
   *
   * Ability to add course to level
   *
   *
   * Ability to add Category of courses to the malla
   */

  /*   let actions = {}; */

  let actions = {};

  actions.populateMalla = function ({ malla }) {
    let normalizeMalla = malla;
    for (let individualmalla of normalizeMalla.malla) {
      for (let i = 0; i < individualmalla.courses.length; i++) {
        let fullcourse = moodle.courses.filter(
          (c) => c.id === Number(individualmalla.courses[i].course_id)
        )[0];
        if (fullcourse) {
          individualmalla.courses[i] = {
            ...individualmalla.courses[i],
            name: fullcourse.fullname,
          };
        }
      }
    }

    setMalla(normalizeMalla);
  };

  actions.fetchInitialMalla = async function () {
    try {

      const jsonData = [{"id":50,"malla":[{"category_id":"19","category_name":"Prevención de riesgo y Seguridad laboral","courses":[{"course_id":"13","state":"Incompleto","provider":"moodle"},{"course_id":"14","state":"Incompleto","provider":"moodle"},{"course_id":"15","state":"Incompleto","provider":"moodle"},{"course_id":"16","state":"Incompleto","provider":"moodle"}]},{"category_id":"22","category_name":"Gestión de sala de ventas","courses":[{"course_id":"17","state":"Incompleto","provider":"moodle"},{"course_id":"18","state":"Incompleto","provider":"moodle"},{"course_id":"19","state":"Incompleto","provider":"moodle"},{"course_id":"20","state":"Incompleto","provider":"moodle"}]},{"category_id":"21","category_name":"Comunicación efectiva","courses":[{"course_id":"21","state":"Incompleto","provider":"moodle"},{"course_id":"22","state":"Incompleto","provider":"moodle"},{"course_id":"23","state":"Incompleto","provider":"moodle"},{"course_id":"24","state":"Incompleto","provider":"moodle"}]},{"category_id":"22","category_name":"Transformación digital","courses":[{"course_id":"25","state":"Incompleto","provider":"moodle"},{"course_id":"26","state":"Incompleto","provider":"moodle"},{"course_id":"27","state":"Incompleto","provider":"moodle"},{"course_id":"28","state":"Incompleto","provider":"moodle"}]}],"descripcion":"Malla Inicial Beta","enabled":false,"relacion":null,"id_ficha":null,"id_nivel":"6"}];
      actions.populateMalla({ malla: jsonData[0] });
    } catch (err) {
      console.log(err);
    }
  };

  let value = {
    malla,
    fetchInitialMalla: actions.fetchInitialMalla,
  };

  return (
    <MallasContext.Provider value={value}>{children}</MallasContext.Provider>
  );
};

export const useMallas = () => useContext(MallasContext);
