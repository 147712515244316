import React, { useEffect, useState } from "react";
import "./styles.css";
import { Layout } from "../../components/Layout";
import { useMallas } from "../../contexts/mallas";
import axios from "axios";
import { useMoodle } from "../../contexts/moodle";
import downloadIcon from "../../assets/images/ico-descarga.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

let colors = {
  bg_grey: "#5e6b8a",
  text: "#f4f4f6",
  yellow: "#fcab11",
  green: "#63b620",
  dark_green: "#2f580e",
  // cian: "#149ba7",
  cian: "#157f94",
  red: "#f8333c",
};

export const MiMalla = () => {
  const moodle = useMoodle();
  const malla = useMallas();

  useEffect(() => {
    !moodle.courses && moodle.getCourses();
    moodle.courses && malla.fetchInitialMalla();
    // eslint-disable-next-line
  }, [moodle.courses]);

  let styles = {};

  styles.container = {
    width: "1200px",
    height: "calc(100vh - 72px)",
    paddingTop: "2rem",
    margin: "0 auto",
  };

  if (!malla.malla) {
    return (
      <Layout footer={false}>
        <MallaSkeleton />
      </Layout>
    );
  }

  return (
    <Layout footer={false}>
      <div style={styles.container}>
        <MallaTitle />
        <MallaHeaders level_id={malla.malla.id_nivel} />
        <MallaGrid isTop>
          {malla.malla.malla.map((malla, idx) => {
            return <MallaRow malla={malla} key={idx} />;
          })}
        </MallaGrid>
        <CourseStateDictionary />
      </div>
    </Layout>
  );
};

const MallaSkeleton = () => {
  return (
    <div
      style={{
        width: "100vw",
        maxWidth: "100vw",
        height: "calc(100vh - 72px)",
        display: "flex",
        justifyContent: "center",
        paddingTop: "1rem",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "1100px",
          height: "100vh",
          display: "flex",
          paddingTop: "3rem",
          flexDirection: "column",
        }}
      >
        <Skeleton
          baseColor="#cccccc"
          style={{
            opacity: 0.5,
            height: 35,
            width: 300,
            marginBottom: 40,
          }}
        />
        <MallaGrid>
          {[1, 2, 3, 4, 5].map((i) => {
            return (
              <Skeleton
                key={i}
                baseColor="#cccccc"
                style={{
                  opacity: 0.5,
                  height: "100%",
                }}
              />
            );
          })}
        </MallaGrid>
        <MallaGrid isTop>
          {[1, 2, 3, 4, 5].map((i) => {
            return (
              <Skeleton
                key={i}
                baseColor="#cccccc"
                style={{
                  opacity: 0.5,
                  height: "100%",
                }}
              />
            );
          })}
        </MallaGrid>
        <MallaGrid>
          {[1, 2, 3, 4, 5].map((i) => {
            return (
              <Skeleton
                key={i}
                baseColor="#cccccc"
                style={{
                  opacity: 0.5,
                  height: "100%",
                }}
              />
            );
          })}
        </MallaGrid>
        <MallaGrid>
          {[1, 2, 3, 4, 5].map((i) => {
            return (
              <Skeleton
                key={i}
                baseColor="#cccccc"
                style={{
                  opacity: 0.5,
                  height: "100%",
                }}
              />
            );
          })}
        </MallaGrid>
      </div>
      <div style={{ paddingTop: 137 }}>
        {[1, 2, 3, 4, 5].map((i) => {
          return (
            <Skeleton
              key={i}
              baseColor="#cccccc"
              style={{
                opacity: 0.5,
                height: 12,
                width: 25,
              }}
            />
          );
        })}
      </div>
      <div style={{ marginLeft: 10, paddingTop: 137 }}>
        {[1, 2, 3, 4, 5].map((i) => {
          return (
            <Skeleton
              key={i}
              baseColor="#cccccc"
              style={{
                opacity: 0.5,
                height: 12,
                width: 155,
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const MallaRow = ({ malla }) => {
  return (
    <>
      <MallaBlockItem text={malla.category_name} color={colors.cian} />
      {malla.courses.map((course, idx) => {
        return (
          <MallaBlockItem
            text={course.name}
            color={idx === 0 ? colors.yellow : colors.text}
            textColor={idx === 0 ? "#fff" : "#212735"}
          />
        );
      })}
      <MallaBlockItem
        text={
          <img
            style={{ marginLeft: "18px" }}
            src={downloadIcon}
            alt="icono descargar"
          />
        }
        color={colors.text}
        maxWidth={"120px"}
      />
    </>
  );
};

const MallaTitle = () => (
  <p
    style={{
      color: "#fff",
      fontSize: "34px",
      fontWeight: "bold",
      marginBottom: "2rem",
    }}
  >
    Mi malla
  </p>
);

const MallaGrid = ({ children, isTop }) => {
  return (
    <div
      style={{
        marginTop: isTop ? "10px" : "10px",
        display: "grid",
        gridTemplateColumns: `repeat(6, 1fr)`,
        gridTemplateRows: "80px",
        gridColumnGap: 10,
        gridRowGap: 10,
        width: "90%",
      }}
    >
      {children}
    </div>
  );
};

const MallaHeaders = ({ level_id }) => {
  const [levels, setLevels] = useState(null);

    useEffect(() => {
        // Simula la obtención de datos a partir del JSON
        const fetchData = () => {
            const levelData = [{"id":"6","nivel_obj":[{"description":"Nivel I"},{"description":"Nivel II"},{"description":"Nivel III"},{"description":"Nivel IV"}],"id_ficha":null,"nombre":"Unidad de Competencia laboral","enabled":true}];
            if (levelData) {
                setLevels(levelData[0]);
            } else {
                console.log("Nivel no encontrado");
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, []);

  if (!levels) {
    return null;
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${levels.nivel_obj.length + 2}, 1fr)`,
        gridTemplateRows: "60px",
        gridColumnGap: 10,
        width: "90%",
      }}
    >
      <MallaBlockItem
        text={levels.nombre}
        color={colors.cian}
        maxHeight={"60px"}
      />
      {levels.nivel_obj.map((level, idx) => {
        return (
          <MallaBlockItem
            text={level.description}
            color={colors.cian}
            key={idx}
            maxHeight={"60px"}
          />
        );
      })}
      <MallaBlockItem
        text={"Certificado"}
        color={colors.cian}
        maxHeight={"60px"}
        maxWidth={"120px"}
      />
    </div>
  );
};

const MallaBlockItem = ({
  text,
  color,
  textColor = "#f4f4f6",
  maxHeight = "80px",
  maxWidth = "210px",
}) => {
  return (
    <div
      style={{
        backgroundColor: color,
        width: "210px",
        maxWidth: maxWidth,
        height: "80px",
        maxHeight: maxHeight,
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        padding: ".2rem 1rem",
        boxShadow: "0px 10px 16px -7px rgba(0,0,0,0.1)",
        border: ".5px solid #b2b2b2",
      }}
    >
      <p
        style={{
          color: textColor,
          margin: 0,
          width: "100%",
          fontWeight: "500",
          lineHeight: "1.3rem",
          fontSize: 15,
        }}
      >
        {text}
      </p>
    </div>
  );
};

const CourseStateDictionary = () => {
  let styles = {};

  styles.text = {
    color: "#fff",
    margin: "2px",
  };

  return (
    <div
      style={{
        width: "fit-content",
        /* marginLeft: "auto", */
        display: "flex",
        flexDirection: "row",
        marginTop: "1rem",
        gap: "2rem",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "26px",
            height: "14px",
            backgroundColor: colors.yellow,
            borderRadius: 4,
            margin: 7,
          }}
        ></div>
        <div>
          <p style={styles.text}>Curso En Progreso</p>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "26px",
            height: "14px",
            backgroundColor: colors.green,
            borderRadius: 4,
            margin: 7,
          }}
        ></div>
        <div>
          <p style={styles.text}>Curso Aprobado</p>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "26px",
            height: "14px",
            backgroundColor: colors.dark_green,
            borderRadius: 4,
            margin: 7,
          }}
        ></div>
        <div>
          <p style={styles.text}>Curso Aprobado RAP</p>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "26px",
            height: "14px",
            backgroundColor: colors.red,
            borderRadius: 4,
            margin: 7,
          }}
        ></div>
        <div>
          <p style={styles.text}>Curso Reprobado</p>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "26px",
            height: "14px",
            backgroundColor: "#fff",
            borderRadius: 4,
            margin: 7,
          }}
        ></div>
        <div>
          <p style={styles.text}>Curso No Inscrito</p>
        </div>
      </div>
    </div>
  );
};
