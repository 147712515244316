import axios from "axios";
import {
  odinBaseUrl,
} from "../api";

export async function getUserNru(courseid, userid) {
    let dataUserOdin = localStorage.getItem('loginUserOdin');
    const userOdin = JSON.parse(dataUserOdin);
    const urlOdin = `${odinBaseUrl}api/camps/merkademia/user-course-sence/${courseid}/${userid}`;

    try {
        const response = await axios.get(urlOdin, {
            headers: {
                'Authorization': `Bearer ${userOdin.api_token}`
            }
        });

        const nru = response.data[0]?.codcourse || false;

        // Guardar el valor en localStorage
        localStorage.setItem('nru', nru);

        //console.log({ nru }); // Confirmar que se asignó
        return nru;
    } catch (error) {
        console.error('Error fetching data:', error);
        return 'error'; // Opcional, manejar el caso en que falle la llamada
    }
}
