import axios from "axios";
import {
    odinBaseUrl,
    odinApiBaseUrl,
    odinAppClientId,
    odinAppClientSecret,
    odinAppGrantType,
    oOdinAppClientId
} from "../api";
import {getCurrentUser} from "../moodle/auth";
import React, {createContext, useContext, useState} from "react";
import {SNACKTYPES, useSnack} from "../../contexts/snack";

const OdinContext = createContext(null);
/* auth_userkey_request_login_url */

export const OdinProvider = ({children}) => {


    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [coursesHistorical, setcoursesHistorical] = React.useState(null);

    let actions = {};

    actions.loadData = function (load) {
        setLoading(load)
    };

    actions.userData = function (user) {
        setUser(user)
    }
    actions.passwordData = function (passw) {
        setPassword(passw)
    }

    actions.loginOdin = function (username, password) {
        return new Promise((resolve, reject) => {
            let tokenO = localStorage.getItem("id_token");
            let url = odinApiBaseUrl + "merkademia/login";
            let userLogin = localStorage.getItem("loginUser");
            userLogin = btoa(userLogin || ""); // Evita errores si `userLogin` es null
            let data = "";

            if (!tokenO) {
                setLoading(true);

                // Solicitar un nuevo token
                axios
                    .post(`${odinBaseUrl}oauth/token`, {
                        client_id: oOdinAppClientId,
                        client_secret: odinAppClientSecret,
                        scope: "*",
                        grant_type: odinAppGrantType,
                    })
                    .then((res) => {
                        if (res.status === 201 || res.status === 200) {
                            // Guardar el token en localStorage y configurar headers
                            window.localStorage.removeItem("id_token");
                            window.localStorage.setItem("id_token", res.data.access_token);
                            axios.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;

                            // Autenticar al usuario con username y password
                            return axios.post(
                                `${url}`,
                                { username: username, password: password },
                                { headers: { Authorization: `Bearer ${res.data.access_token}` } }
                            );
                        } else {
                            throw new Error("Error al obtener el token");
                        }
                    })
                    .then((resp) => {
                        data = JSON.stringify(resp.data);
                        setLoading(false);

                        localStorage.removeItem("loginUserOdin");
                        localStorage.setItem("loginUserOdin", data);

                        if (resp.data.error) {
                            throw new Error(resp.data.error);
                        }

                        resolve(data);
                    })
                    .catch((error) => {
                        console.error("Error durante el login:", error);
                        setLoading(false);
                        reject(error);
                    });
            } else {
                const userData = localStorage.getItem("loginUserOdin");
                if (userData) {
                    resolve(userData);
                } else {
                    reject("No se encontró loginUserOdin en localStorage");
                }
            }
        });
    };

    actions.dataOdinCourse = function () {
        setLoading(true); // Inicia el estado de carga

        return new Promise((resolve, reject) => {
            let dataUserOdin = localStorage.getItem("loginUserOdin");
            let usuario_alumno = "";
            let api_token = "";

            // Verificar si hay datos en localStorage
            if (!dataUserOdin) {
                reject("No se encontró loginUserOdin en localStorage");
                setLoading(false);
                return;
            } else {
                const userOdin = JSON.parse(dataUserOdin);
                usuario_alumno = userOdin.user.usuario_alumno;
                api_token = userOdin.api_token;
            }

            // Construir URL
            const urlOdin =
                odinBaseUrl + "api/camps/user-courses?username=" + usuario_alumno;

            // Realizar la llamada API
            axios
                .get(`${urlOdin}`, {
                    headers: {
                        Authorization: `Bearer ${api_token}`,
                    },
                })
                .then((response) => {
                    const data = response.data.data;
                    setLoading(false);
                    resolve(data);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setLoading(false);
                    reject(error);
                });
        });
    };

    let values = {
        loading,
        coursesHistorical,
        user,
        password,
        actions,
        dataOdin: actions.dataOdin,
        loginOdin: actions.loginOdin,
        dataOdinCourse: actions.dataOdinCourse
    };
    return (
        <OdinContext.Provider value={values}>{children}</OdinContext.Provider>
    );
};

export const useOdin = () => React.useContext(OdinContext);

